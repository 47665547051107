// Inspired by:
// https://dev.to/gugaguichard/creating-a-github-like-progress-bar-for-your-remix-app-153l

import { cn } from '@hapstack/common'
import { useFetchers, useNavigation } from '@remix-run/react'
import { useEffect, useMemo, useRef, useState } from 'react'

function GlobalLoading() {
  const navigation = useNavigation()

  const fetchers = useFetchers()

  const activeFetcher = useMemo(
    () => fetchers.find((f) => f.state !== 'idle'),
    [fetchers]
  )

  const fetcherIsActive = Boolean(activeFetcher)

  const navigationIsActive = navigation.state !== 'idle'

  const active = fetcherIsActive || navigationIsActive

  const activeElement = fetcherIsActive ? activeFetcher : navigation

  const ref = useRef<HTMLDivElement>(null)
  const [animationComplete, setAnimationComplete] = useState(true)

  useEffect(() => {
    if (!ref.current) return
    if (active) setAnimationComplete(false)

    Promise.allSettled(
      ref.current.getAnimations().map(({ finished }) => finished)
    ).then(() => !active && setAnimationComplete(true))
  }, [active])

  return (
    <div
      role="progressbar"
      aria-hidden={!active}
      aria-valuetext={active ? 'Loading' : undefined}
      className="fixed inset-x-0 top-0 z-50 h-1 animate-pulse"
    >
      <div
        className={cn(
          'h-full bg-gradient-to-r from-accent to-white transition-all duration-500 ease-in-out',
          activeElement?.state === 'idle' &&
            animationComplete &&
            'w-0 opacity-0 transition-none',
          activeElement?.state === 'submitting' && 'w-4/12',
          activeElement?.state === 'loading' && 'w-10/12',
          activeElement?.state === 'idle' && !animationComplete && 'w-full'
        )}
      />
    </div>
  )
}

export { GlobalLoading }
