import { OG_IMAGE_URL } from '@hapstack/constants/marketing'
import { GlobalLoading, useToaster } from '@hapstack/remix'
import styles from '@hapstack/tailwind-config/tailwind.css?url'
import { iconSpriteHref, Toaster, TooltipProvider } from '@hapstack/ui'
import type { LoaderFunctionArgs, SerializeFrom } from '@remix-run/node'
import { json } from '@remix-run/node'
import type { MetaFunction } from '@remix-run/react'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'

import { getToast } from '~/utils/toast.server'

import { AppLayout } from './components/layouts/AppLayout'
import { RouteErrorCard } from './components/shared/RouteErrorCard'
import { env } from './utils/env.server'

declare global {
  interface Window {
    ENV: SerializeFrom<typeof loader>['ENV']
  }
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    {
      title: `${data?.ENV.CONSTANTS.APP_NAME}${data?.ENV.APP_ENV === 'demo' ? ' | Live Demo' : ''}`,
    },
    {
      name: 'viewport',
      content: 'width=device-width,initial-scale=1,minimum-scale=1',
    },
    { name: 'charset', content: 'utf-8' },
    {
      property: 'og:image',
      content: OG_IMAGE_URL,
    },
  ]
}

export const links = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'preload', href: iconSpriteHref, as: 'image', type: 'image/svg+xml' },
]

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { toast, headers } = await getToast(request)

  return json(
    {
      toast,

      ENV: {
        APP_ENV: env.APP_ENV,
        APP_VERSION: env.APP_VERSION,
        CONSTANTS: env.CONSTANTS,
        INTERCOM_APP_ID: env.INTERCOM_APP_ID,
        POSTHOG_API_KEY: env.POSTHOG_API_KEY, // Safe public key
        SENTRY_DSN: env.SENTRY_DSN, // Safe public key
        SENTRY_RELEASE: env.SENTRY_RELEASE,
      },
    },
    { headers }
  )
}

function App() {
  const { ENV, toast } = useLoaderData<typeof loader>()
  useToaster(toast)

  return (
    <html
      lang="en"
      className="scroll-smooth antialiased"
    >
      <head>
        <Meta />
        <Links />
      </head>
      <body className="font-sans text-md text-primary">
        <GlobalLoading />

        <TooltipProvider delayDuration={200}>
          <Outlet />
          <ScrollRestoration
            getKey={(location) => {
              return location.pathname
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(ENV)}`,
            }}
          />

          <Scripts />
          <Toaster />
        </TooltipProvider>
      </body>
    </html>
  )
}

export default withSentry(App, { wrapWithErrorBoundary: true })

export function ErrorBoundary() {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  return (
    <html>
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>

      <body>
        <AppLayout>
          <main className="container max-w-2xl p-4 md:p-10">
            <RouteErrorCard />
          </main>
        </AppLayout>
        <Scripts />
      </body>
    </html>
  )
}
