import { toast as setToast } from '@hapstack/ui'
import type { SerializeFrom } from '@remix-run/node'
import { useEffect } from 'react'

import type { ServerToast } from '../utils'

export const useToaster = (toast: SerializeFrom<ServerToast>) => {
  useEffect(() => {
    if (!toast) return

    const { variant, title, ...options } = toast

    switch (variant) {
      case 'success':
        setToast.success(title, options)
        break
      case 'error':
        setToast.error(title, options)
        break
      default:
        setToast.info(title, options)
    }
  }, [toast])
}
